// CSS
require('../css/animate.css');
require('../css/bootstrap.min.css');
require('../css/flaticon.css');
require('../css/font-awesome.min.css');
require('../css/gijgo.css');
require('../css/magnific-popup.css');
require('../css/nice-select.css');
require('../../node_modules/owl.carousel/dist/assets/owl.carousel.css');
require('../css/slick.css');
require('../css/slicknav.css');
require('../css/theme-default.css');
require('../css/themify-icons.css');
require('../css/flaticon.css');
require('../css/style.css');

//JS
window.$ = require('jquery');
window.jQuery = require('jquery');
require('./vendor/modernizr-3.5.0.min.js');
require('./bootstrap.min.js');
require('./gijgo.min.js');
require('./imagesloaded.pkgd.min.js');
require('./isotope.pkgd.min.js');
require('./jquery.counterup.min.js');
require('./jquery.form.js');
require('owl.carousel');
require('./jquery.magnific-popup.min.js');
require('./jquery.scrollUp.min.js');
require('./jquery.slicknav.min.js');
require('./nice-select.min.js');
require('./plugins.js');
require('./scrollIt.js');
require('./slick.min.js');
require('./wow.min.js');
require('./main.js');
